<template>
  <div>
    <div class="row row-sm">
      <div class="col-3">
        <label for="">Client <span class="text-danger">*</span> </label>
        <select
          v-model="filter.customer_reference"
          class="form-select"
          required
        >
          <option selected value="">--</option>
          <option
            v-for="(customer, index) in customers"
            :key="index++"
            :value="customer.reference"
            :style="
              customer.blocked || customer.fullName.includes('Bloqué')
                ? { color: 'red' }
                : ''
            "
          >
            {{ customer.fullName }}
          </option>
        </select>
      </div>

      <div class="col">
        <label for="">Du <span class="text-danger">*</span> </label>
        <input v-model="filter.startDate" type="date" class="form-control" />
      </div>
      <div class="col">
        <label for=""> Au <span class="text-danger">*</span> </label>
        <input v-model="filter.endDate" type="date" class="form-control" />
      </div>

      <div class="col-auto">
        <label for=""> </label>
        <button @click="toFilter(filter)" class="btn btn0 btn-secondary">
          <i class="bi bi-funnel"></i> Rechercher
        </button>
      </div>

      <div class="col-auto">
        <label for=""> </label>
        <button @click="print(filter)" class="btn btn-secondary">
          <i class="bi bi-printer"></i> Imprimer
        </button>
      </div>
    </div>
    <br />
    <div class="row justify-content-end">
      <div class="col-auto">
        <button @click="refresh()" class="btn btn0 btn-secondary">
          <i class="bi bi-arrow-clockwise"></i>
          Actualiser
        </button>
      </div>
      <div class="col-auto">
        <button
          @click="$router.push({ name: 'discounts-create' })"
          class="btn btn-primary"
        >
          <i class="bi bi-plus-square"></i>
          Créer un nouveau remise
        </button>
      </div>
    </div>
    <br />
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col">Référence</th>
          <th scope="col">Client</th>
          <th scope="col">Date</th>
          <th scope="col">M. Remise</th>
          <th scope="col">Remarque</th>
          <th scope="col">Créer par</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(discount, index) in discounts" :key="index++">
          <td>
            {{ index }}
          </td>
          <td>
            {{ discount.reference }}
          </td>
          <td>
            <span
              v-if="discount.customer"
              :class="
                discount.customer.deleted_at
                  ? 'text-danger text-decoration-line-through'
                  : ''
              "
            >
              {{ discount.customer.fullName }}
            </span>
          </td>
          <td>{{ discount.date }}</td>

          <td class="text-success fw-bold">
            <span v-if="discount.amount >= 0">
              {{ discount.amount | toFixedWithSpace }}
            </span>
          </td>

          <td>
            {{ discount.remark }}
          </td>
          <td>
            <span v-if="discount.user">
              {{ discount.user.name }}
            </span>
          </td>
          <td  class="p-0 m-0">
            <button
              @click="
                $router.push({
                  name: 'discounts-details',
                  params: { reference: discount.reference },
                })
              "
              class="btn"
            >
              <i class="bi bi-info-square fs-5"></i>
            </button>
          </td>

        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      search_value: "",
      addedToday: false,
      sortValue: "",
      filter: {
        startDate: new Date(new Date().setFullYear(new Date().getFullYear()))
          .toLocaleString("en-CA")
          .split(",")[0],
        endDate: new Date(new Date().setFullYear(new Date().getFullYear()))
          .toLocaleString("en-CA")
          .split(",")[0],
      },
    };
  },
  computed: {
    ...mapGetters("discount", {
      discounts: "getAll",
    }),
    ...mapGetters("customer", {
      customers: "getAll",
    }),
    ...mapGetters("auth", {
      userRole: "getUserRole",
    }),
  },
  mounted() {
    this.$store.dispatch("discount/getAll");
    this.$store.dispatch("customer/getAll");
  },
  methods: {
    async search(value) {
      return await this.$store.commit("discount/search", value);
    },
    async refresh() {
      return await this.$store.dispatch("discount/getAll");
    },

    async listDiscounts(checkbox) {
      if (checkbox == true) {
        console.log(checkbox);
        return await this.$store.commit("discount/getTodays");
      } else {
        return await this.$store.dispatch("discount/getDiscounts");
      }
    },
    async sort(value) {
      return await this.$store.commit("discount/sort", value);
    },
    async toFilter(data) {
      return await this.$store.dispatch("discount/filter", data);
    },
    async print(data) {
      await this.$store.dispatch("discount/print", data);
    },
  },
  filters: {
    toFixedWithSpace: (value) => {
      return parseFloat(value)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
    },
  },
};
</script>
